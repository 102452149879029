import Vue from "vue";
import App from "@/App.vue";
import router from "@/router/routes";
import store from "@/state/store/index.js";
import "@/state/helpers/index.js";
import "@/design/index.scss";

import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);

import VueTranslate from "vue-translate-plugin";
Vue.use(VueTranslate);

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import VueMask from "v-mask";
Vue.use(VueMask);

import VueRouter from "vue-router";
Vue.use(VueRouter);

import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);

import ToastPlugin from "vue-toast-notification";
Vue.use(ToastPlugin, {
  position: "top-right",
  duration: 50000,
  dismissible: true,
});
import "vue-toast-notification/dist/theme-bootstrap.css";

import VueSocialSharing from "vue-social-sharing";
Vue.use(VueSocialSharing);

import PrimeVue from "primevue/config";
import PrimeLocale from "@/state/locale/primevue.pt-br.json";
import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
import "primeicons/primeicons.css";
Vue.use(PrimeVue, {
  locale: PrimeLocale,
});

Vue.use(require("vue-moment"));

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
