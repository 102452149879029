import Vue from "vue";
import Router from "vue-router";
import { api } from "@/state/services";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: "/",
  routes: [
    {
      path: "/",
      component: () => import("./views/home"),
      meta: { authRequired: true },
    },
    {
      path: "/login",
      component: () => import("./views/login"),
      meta: { authRequired: false },
    },
    {
      path: "/login/token/:token",
      component: () => import("./views/login-in"),
      meta: { authRequired: false },
    },
    {
      path: "/logout",
      redirect: "/login",
    },
    {
      path: "/register",
      component: () => import("./views/register"),
      meta: { authRequired: false },
    },
    {
      path: "/register/:sponsor",
      component: () => import("./views/register"),
      meta: { authRequired: false, sponsored: 'any' },
    },
    {
      path: "/password-forgot",
      component: () => import("./views/password-forgot"),
      meta: { authRequired: false },
    },
    {
      path: "/password-change",
      component: () => import("./views/password-change"),
      meta: { authRequired: false },
    },
    {
      path: "/account",
      component: () => import("./views/account/account"),
      meta: { authRequired: true },
    },
    {
      path: "/account/pix",
      component: () => import("./views/account/pix"),
      meta: { authRequired: true },
    },
    {
      path: "/account/password",
      component: () => import("./views/account/password"),
      meta: { authRequired: true },
    },
    {
      path: "/account/password/security",
      component: () => import("./views/account/password-security"),
      meta: { authRequired: true },
    },
    {
      path: "/account/password/security/:token",
      component: () => import("./views/account/password-security"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/extract",
      component: () => import("./views/wallet/extract"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/withdrawal",
      component: () => import("./views/wallet/withdrawal"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/withdrawal/history",
      component: () => import("./views/wallet/withdrawal-history"),
      meta: { authRequired: true },
    },
    {
      path: "/sales/orders",
      component: () => import("./views/sales/orders"),
      meta: { authRequired: true },
    },
    {
      path: "/sales/orders/:id",
      component: () => import("./views/sales/orders-view"),
      meta: { authRequired: true },
    },
    {
      path: "/sales/clients",
      component: () => import("./views/sales/clients"),
      meta: { authRequired: true },
    },
    {
      path: "/sales/reports",
      component: () => import("./views/sales/reports"),
      meta: { authRequired: true },
    },
    {
      path: "/reports/sales",
      component: () => import("./views/reports/sales"),
      meta: { authRequired: true },
    },
    {
      path: "/inventory",
      redirect: "/inventory/products",
    },
    {
      path: "/inventory/products",
      component: () => import("./views/inventory/products"),
      meta: { authRequired: true },
    },
    {
      path: "/inventory/restock",
      redirect: "/inventory/partners",
    },
    {
      path: "/inventory/partners",
      component: () => import("./views/inventory/partners"),
      meta: { authRequired: true, sponsored: 'any' },
    },
    {
      path: "/inventory/partners/:id",
      component: () => import("./views/inventory/partners-view"),
      meta: { authRequired: true, sponsored: 'any' },
    },
    {
      path: "/inventory/carts",
      component: () => import("./views/inventory/carts"),
      meta: { authRequired: true, sponsored: 'any' },
    },
    {
      path: "/inventory/carts/:id",
      component: () => import("./views/inventory/carts-partner"),
      meta: { authRequired: true, sponsored: 'any' },
    },
    {
      path: "/inventory/orders",
      component: () => import("./views/inventory/orders"),
      meta: { authRequired: true, sponsored: 'any' },
    },
    {
      path: "/inventory/order/:id",
      component: () => import("./views/inventory/order"),
      meta: { authRequired: true, sponsored: 'any' },
    },
    {
      path: "/settings",
      component: () => import("./views/settings/index"),
      meta: { authRequired: true },
    },
    {
      path: "/integrations",
      component: () => import("./views/integrations/index"),
      meta: { authRequired: true },
    },
    {
      path: "/membership",
      component: () => import("./views/membership/index"),
      meta: { authRequired: true },
    },
    {
      path: "/membership/order/:id",
      component: () => import("./views/membership/order"),
      meta: { authRequired: true },
    },
    {
      path: "/404",
      component: () => import("./views/404"),
      meta: { authRequired: false },
    },
    {
      path: "/:sponsor",
      redirect: "/register/:sponsor",
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token");

  document.title = process.env.VUE_APP_TITLE;
  next();

  if (to.meta.authRequired && !loggedIn) {
    return next("/login");
  }

  if (to.meta.authRequired && loggedIn) {
    api
      .get("user")
      .then((response) => {
        if (response.data && response.data.status == "success") {
          router.app.$store.state.account.user = response.data.user;

          if (
              response.data.user.membership &&
              response.data.user.membership.status &&
              response.data.user.membership.status == "inactive" &&
              to.path != "/membership" &&
              to.path != "/membership/order/" + response.data.user.membership.order
            ) {
            return next("/membership");
          }

          return next();
        } else {
          return next("/login");
        }
      })
      .catch(error => {
        if (error) {
          return next("/login");
        }
      });
  } else {
    next();
  }
});

export default router;
